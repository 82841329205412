import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../app/store';

import { NavItem } from '../../data/Data';
import { fetchSculptureCategories } from '../models/sculpturesSlice';
import { fetchMusicCategories } from '../models/musicSlice';

export const useNavBar = () => {
  const dispatch = useDispatch();
  const sculptureCategories = useSelector(
    (store: RootState) => store.sculptures.categories
  );
  const musicCategories = useSelector(
    (store: RootState) => store.music.musicCategoryObjects
  );

  useEffect(() => {
    dispatch(fetchMusicCategories());
    dispatch(fetchSculptureCategories());
  }, [dispatch]);

  let id: number = 0;

  const home: NavItem = {
    id: id,
    title: 'Home',
    link: '/',
    megaMenu: false,
  };

  id += 1;

  const musicMenu: NavItem = {
    id: id,
    title: 'Music',
    link: '#',
    subMenu:
      musicCategories.length > 0
        ? musicCategories.map((cat) => {
            id += 1;
            return {
              id: id,
              title: cat.name,
              link: `${process.env.PUBLIC_URL}/music/${cat.name}`,
            };
          })
        : undefined,
    megaMenu: false,
  };

  id += 1;

  const sculptureMenu: NavItem = {
    id: id,
    title: 'Sculpture',
    link: '#',
    subMenu:
      sculptureCategories.length > 0
        ? sculptureCategories.map((cat) => {
            id += 1;
            return {
              id: id,
              title: cat.name,
              link: `${process.env.PUBLIC_URL}/sculpture/${cat.name}`,
            };
          })
        : undefined,
    megaMenu: false,
  };

  id += 1;

  const contact = {
    id: id,
    title: 'Contact',
    link: '/contact',
    megaMenu: false,
  };

  return [home, musicMenu, sculptureMenu, contact] as NavItem[];
};
