import React, { Fragment } from 'react';
import LogoDark from '../assets/images/logo.png';
import LogoLight from '../assets/images/logo-light.png';

type Props = {
  sideHeader?: boolean;
};

const Logo = ({ sideHeader }: Props) => {
  return (
    <Fragment>
      {sideHeader ? (
        <a href={`${process.env.PUBLIC_URL}`}>
          <img src={LogoDark} alt="logo" />
          <img src={LogoLight} className="light-logo" alt="logo" />
        </a>
      ) : (
        <div
          className="header-logo col-lg-2 col-6 mt-40 mb-40"
          style={{
            paddingRight: '15px',
            paddingLeft: '15px',
          }}>
          <a href={`${process.env.PUBLIC_URL}`}>
            <img src={LogoDark} alt="logo" />
            <img src={LogoLight} className="light-logo" alt="logo" />
          </a>
        </div>
      )}
    </Fragment>
  );
};

export default Logo;
