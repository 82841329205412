import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PortfolioContainer } from './PortfolioContainer';
import { Drawing, fetchDrawings } from '../features/models/drawingsSlice';
import { RootState } from '../app/store';

const DrawingsContainer = () => {
  const [portfolioDrawings, setPortfolioDrawings] = useState<Drawing[]>([]);
  const drawings = useSelector(
    (state: RootState) => state.drawings.drawingObjects
  );
  const drawingsLoading = useSelector(
    (state: RootState) => state.drawings.isLoading
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDrawings());
  }, [dispatch]);

  useEffect(() => {
    setPortfolioDrawings(drawings.filter((drawing) => drawing.show));
  }, [drawings]);

  return (
    <PortfolioContainer
      portfolios={portfolioDrawings}
      loading={drawingsLoading}
    />
  );
};

export default DrawingsContainer;
