import React from 'react';
import 'perfect-scrollbar-react/dist/style.min.css';

import Portfolio from '../components/Portfolio';
import Loading from '../components/Loading';
import { useStyles } from '../features/hooks/useStyles';
import { MusicCategory } from '../features/models/musicSlice';
import { Drawing } from '../features/models/drawingsSlice';
import { Sculpture } from '../features/models/sculpturesSlice';

type Props = {
  portfolios: (Sculpture | MusicCategory | Drawing)[];
  loading: boolean;
};

export const PortfolioContainer = ({ portfolios, loading }: Props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.portfolioImageContainer}>
        {portfolios.map((portfolio) => {
          return (
            <div
              className={classes.portfolioImageElement}
              key={`${portfolio.thumb}`}>
              <Portfolio item={portfolio} />
            </div>
          );
        })}
      </div>
      {loading ? <Loading /> : null}
    </>
  );
};

export default PortfolioContainer;
