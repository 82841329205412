import React from 'react';
import { Link } from 'react-router-dom';

import { useStyles } from '../features/hooks/useStyles';
import { Sculpture } from '../features/models/sculpturesSlice';
import { Drawing } from '../features/models/drawingsSlice';
import { MusicCategory } from '../features/models/musicSlice';
import { getPortfolioDetailUrl } from '../features/utils/objectUtils';

type Props = {
  item: Sculpture | Drawing | MusicCategory;
};

const Portfolio = ({ item }: Props) => {
  const { name, thumb } = item;
  const url = getPortfolioDetailUrl(item);
  const categoryName = item.hasOwnProperty('categoryDetails')
    ? (item as Sculpture).categoryDetails.name
    : '';
  const href = `${process.env.PUBLIC_URL}/${url}`;
  const classes = useStyles();

  return (
    <div
      className={`portfolio-item ${classes || ' '}`}
      style={{ objectFit: 'fill' }}>
      <Link to={href} className="d-block">
        <div className="portfolio-image" style={{ objectFit: 'fill' }}>
          <img src={thumb} alt={name} />
        </div>
        <div className="portfolio-content">
          <h4 className="title">{name}</h4>
          <div className="category">
            <span>{categoryName}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Portfolio;
