import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MusicPlayer from '../components/MusicPlayer';
import {
  fetchMusicCategories,
  MusicTrack,
} from '../features/models/musicSlice';
import { RootState } from '../app/store';

type Params = {
  category: string;
};

const MusicPageContainer = () => {
  const dispatch = useDispatch();
  const { category } = useParams<Params>();
  const allCategories = useSelector(
    (store: RootState) => store.music.musicCategoryObjects
  );
  const [tracks, setTracks] = useState<MusicTrack[]>([]);

  useEffect(() => {
    dispatch(fetchMusicCategories());
  }, [dispatch]);

  useEffect(() => {
    const selectedCategory = allCategories.find(
      (entity) => entity.name.toLowerCase() === category.toLowerCase()
    );
    if (selectedCategory) {
      setTracks(selectedCategory.tracks.filter((track) => track.show));
    }
  }, [allCategories, category]);

  return <MusicPlayer sourceList={tracks} />;
};

export default MusicPageContainer;
