import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../app/store';
import { fetchSculptures, Sculpture } from '../features/models/sculpturesSlice';
import PortfolioContainer from './PortfolioContainer';

type Params = {
  category: string;
};

export const SculpturesContainer = () => {
  const dispatch = useDispatch();
  const { category } = useParams<Params>();
  const allSculptures = useSelector(
    (store: RootState) => store.sculptures.sculptureObjects
  );
  const loading = useSelector((store: RootState) => store.sculptures.isLoading);
  const [sculptures, setSculptures] = useState<Sculpture[]>([]);

  useEffect(() => {
    dispatch(fetchSculptures());
  }, [dispatch]);

  useEffect(() => {
    if (category) {
      setSculptures(
        allSculptures
          .filter((sculpture) => sculpture.categoryDetails.name === category)
          .filter((sculpture) => sculpture.show)
      );
    } else {
      setSculptures(allSculptures.filter((sculpture) => sculpture.show));
    }
  }, [allSculptures, category]);

  return <PortfolioContainer portfolios={sculptures} loading={loading} />;
};
