import { MusicCategory } from '../models/musicSlice';
import { Sculpture } from '../models/sculpturesSlice';
import { Drawing } from '../models/drawingsSlice';

const SculptureKeys: string[] = [
  'categoryDetails',
  'description',
  'id',
  'images',
  'isFeatured',
  'madeOn',
  'name',
  'order',
  'show',
  'thumb',
  'thumbFilename',
];

const DrawingKeys: string[] = [
  'description',
  'id',
  'image',
  'imageDetails',
  'isFeatured',
  'madeOn',
  'name',
  'order',
  'show',
  'thumb',
  'thumbFilename',
];

const MusicCategoryKeys: string[] = [];

const doKeysMatch = (objectOne: string[], objectTwo: string[]) => {
  let isMatch = true;
  objectOne.forEach((key, index) => {
    if (key !== objectTwo[index]) {
      isMatch = false;
    }
  });
  return isMatch;
};

export const getPortfolioDetailUrl = (
  myObject: Sculpture | MusicCategory | Drawing
) => {
  const myObjectKeys = Object.keys(myObject).sort();
  const numObjectKeys = myObjectKeys.length;

  const sculpture =
    numObjectKeys === SculptureKeys.length
      ? doKeysMatch(myObjectKeys, SculptureKeys)
      : false;
  const drawing =
    numObjectKeys === DrawingKeys.length
      ? doKeysMatch(myObjectKeys, DrawingKeys)
      : false;
  const musicCategory =
    numObjectKeys === MusicCategoryKeys.length
      ? doKeysMatch(myObjectKeys, MusicCategoryKeys)
      : false;

  switch (true) {
    case sculpture:
      return `sculpture-details/${myObject.id}`;
    case drawing:
      return `drawing-details/${myObject.id}`;
    case musicCategory:
      return myObject.name;
    default:
      return 'error';
  }
};
