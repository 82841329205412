import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import appReducer from '../features/appSlice';
import counterReducer from '../features/counter/counterSlice';
import drawingsReducer from '../features/models/drawingsSlice';
import sculpturesReducer from '../features/models/sculpturesSlice';
import musicReducer from '../features/models/musicSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    counter: counterReducer,
    drawings: drawingsReducer,
    sculptures: sculpturesReducer,
    music: musicReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
