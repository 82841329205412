import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from '../app/store';
import { fetchDrawings } from '../features/models/drawingsSlice';
import PortfolioDetails from '../components/PortfolioDetails';
import { DrawingDetailsImage } from '../components/DrawingDetailsImage';
import { useStyles } from '../features/hooks/useStyles';
import Lightbox from 'react-image-lightbox';

type ParamType = {
  id: string;
};

export const DrawingDetails = () => {
  const { id } = useParams<ParamType>();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const classes = useStyles();
  let drawing = useSelector((state: RootState) =>
    state.drawings.drawingObjects.find(
      (drawing) => drawing.id.toString() === id
    )
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDrawings());
  }, [dispatch]);

  if (drawing) {
    const { imageDetails } = drawing;
    return (
      <div className={classes.drawingDetailsContainer}>
        <DrawingDetailsImage
          path={imageDetails.filePath}
          onImageClicked={() => setLightboxOpen(true)}
        />
        <PortfolioDetails content={drawing} />
        {lightboxOpen && (
          <Lightbox
            mainSrc={imageDetails.filePath}
            onCloseRequest={() => setLightboxOpen(false)}
          />
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default DrawingDetails;
