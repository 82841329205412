import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AppState = {
  isMobile: boolean;
};

const initialState: AppState = {
  isMobile: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = true;
    },
  },
});

export const { setIsMobile } = appSlice.actions;

export default appSlice.reducer;
