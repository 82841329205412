import React from 'react';
import { Drawing } from '../features/models/drawingsSlice';
import { Sculpture } from '../features/models/sculpturesSlice';
import { useShareLinks } from '../features/hooks/useShareLinks';

type Props = {
  content: Drawing | Sculpture;
};

const PortfolioDetails = (props: Props) => {
  const { name, description, madeOn } = props.content;
  const selfUrl = window.location.href;
  const [fbShare, twitterShare, pinterestShare] = useShareLinks(selfUrl);

  return (
    <div className="portfolio-details-content">
      {/*
            <a href="/" className="category">
                Drawing
            </a>
        */}

      <h1 className="title">{name}</h1>

      <p>{description}</p>
      {madeOn && (
        <ul className="project-info">
          <li>
            <span>Date:</span> {madeOn}
          </li>
        </ul>
      )}

      <div className="project-share">
        <span>Share:</span>
        <a href={selfUrl}>
          <i
            className="fa fa-facebook"
            onClick={() =>
              window.open(
                fbShare,
                'popup',
                'width=600,height=600,scrollbars=no,resizable=no'
              )
            }
          />
        </a>
        <a
          href={selfUrl}
          onClick={() =>
            window.open(
              twitterShare,
              'popup',
              'width=600,height=600,scrollbars=no,resizable=no'
            )
          }>
          <i className="fa fa-twitter" />
        </a>
        <a
          href={selfUrl}
          onClick={() =>
            window.open(
              pinterestShare,
              'popup',
              'width=600,height=600,scrollbars=no,resizable=no'
            )
          }>
          <i className="fa fa-pinterest-p" />
        </a>
      </div>
    </div>
  );
};

export default PortfolioDetails;
