import React from 'react';
import { useStyles } from '../features/hooks/useStyles';

export const ErrorPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.errorPage}>
      Something went wrong. Please go back and try again.
    </div>
  );
};
