import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../../app/store';
import { api } from '../api';

export type SculptureCategory = {
  id: number;
  name: string;
  order: number;
  show: boolean;
};

export type SculptureImage = {
  id: number;
  name: string;
  filePath: string;
  filename: string;
};

export type Sculpture = {
  id: number;
  name: string;
  description: string;
  show: boolean;
  isFeatured: boolean;
  madeOn: string;
  categoryDetails: SculptureCategory;
  thumb: string;
  thumbFilename: string;
  order: number;
  images: SculptureImage[];
};

type SculptureState = {
  isLoading: boolean;
  sculptureObjects: Sculpture[];
  error: boolean | string;
  categories: SculptureCategory[];
};

const initialState: SculptureState = {
  isLoading: false,
  sculptureObjects: [],
  error: false,
  categories: [],
};

export const sculptureSlice = createSlice({
  name: 'sculptures',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    sculpturesSuccess: (state, action: PayloadAction<Sculpture[]>) => {
      state.sculptureObjects = action.payload;
      state.isLoading = false;
    },
    sculptureCategoriesSuccess: (
      state,
      action: PayloadAction<SculptureCategory[]>
    ) => {
      state.categories = action.payload;
      state.isLoading = false;
    },
    hasError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  sculpturesSuccess,
  hasError,
  startLoading,
  sculptureCategoriesSuccess,
} = sculptureSlice.actions;

export const fetchSculptures = (): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api
      .get('/sculptures')
      .then((response) => dispatch(sculpturesSuccess(response.data)));
  } catch (e) {
    dispatch(hasError(e.message));
  }
};

export const fetchSculptureCategories = (): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api
      .get('/sculpture_categories')
      .then((response) => dispatch(sculptureCategoriesSuccess(response.data)));
  } catch (e) {
    dispatch(hasError(e.message));
  }
};

export default sculptureSlice.reducer;
