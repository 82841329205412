import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PortfolioContainer } from './PortfolioContainer';
import { fetchDrawings } from '../features/models/drawingsSlice';
import { fetchSculptures } from '../features/models/sculpturesSlice';
import { RootState } from '../app/store';
import {
  fetchMusicCategories,
  MusicCategory,
} from '../features/models/musicSlice';
import { Drawing } from '../features/models/drawingsSlice';
import { Sculpture } from '../features/models/sculpturesSlice';

const FrontPageContainer = () => {
  const [featured, setFeatured] = useState<
    (Sculpture | MusicCategory | Drawing)[]
  >([]);
  const drawings = useSelector(
    (state: RootState) => state.drawings.drawingObjects
  );
  const musicCategories = useSelector(
    (store: RootState) => store.music.musicCategoryObjects
  );
  const sculptures = useSelector(
    (state: RootState) => state.sculptures.sculptureObjects
  );

  const loading = useSelector((state: RootState) => {
    return (
      state.drawings.isLoading ||
      state.music.isLoading ||
      state.sculptures.isLoading
    );
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDrawings());
    dispatch(fetchSculptures());
    dispatch(fetchMusicCategories());
  }, [dispatch]);

  useEffect(() => {
    const featuredMusicCategories = musicCategories.filter(
      (cat) => cat.isFeatured
    );
    const featuredSculptures = sculptures.filter(
      (sculpt) => sculpt.show && sculpt.isFeatured
    );
    const featuredDrawings = drawings.filter(
      (drawing) => drawing.show && drawing.isFeatured
    );
    setFeatured(
      (featuredDrawings as (Sculpture | MusicCategory | Drawing)[])
        .concat(featuredMusicCategories, featuredSculptures)
        .sort((a, b) => a.order - b.order)
    );
  }, [drawings, sculptures, musicCategories]);

  return <PortfolioContainer portfolios={featured} loading={loading} />;
};

export default FrontPageContainer;
