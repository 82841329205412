import React from 'react';
import { Card } from '@material-ui/core';
import DenverSkyline from '../assets/images/denver-skyline.jpg';

export const Contact = () => {
  return (
    <Card style={{width: '50%', height: '50%', padding: 0, display: 'flex', flexDirection: 'row', overflow: 'hidden' }} elevation={5}>
        <div><img src={DenverSkyline} height={'100%'} width={'100%'}/></div>
        <div style={{padding: 15, flex:1}}>
      <div>
        Sean Finley is a multi-instrumentalist, composer, and artist in the
        Denver metro area. He Founded One Good Ear Studio in 2015 to connect
        with filmmakers, game developers, and other musicians.
      </div>
      <div style={{paddingTop: 20}}>
        If you're interested in discussing a project, collaborating or just want
        to say hi contact <b>seanfinleymusic@gmail.com</b>.
      </div>
        </div>
    </Card>
  );
};
