import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavBar } from '../features/hooks/useNavBar';

const NavItem = () => {
  const navBarData = useNavBar();
  const navbarItems = navBarData.map((navItem) => (
    <li
      key={navItem.id}
      className={navItem.subMenu ? 'menu-item-has-children' : ''}>
      <NavLink
        exact={navItem.link === `/`}
        to={`${process.env.PUBLIC_URL + navItem.link}`}
        activeClassName="active">
        {navItem.title}
      </NavLink>

      {navItem.subMenu ? (
        <ul className="sub-menu">
          {navItem.subMenu.map((subItem) => (
            <li
              key={subItem.id}
              className={subItem.subMenu ? 'menu-item-has-children' : ''}>
              <NavLink to={`${process.env.PUBLIC_URL + subItem.link}`}>
                {subItem.title}
              </NavLink>

              {subItem.subMenu ? (
                <ul className="sub-menu">
                  {subItem.subMenu.map((dropSubItem) => (
                    <li key={dropSubItem.id}>
                      <NavLink
                        to={`${process.env.PUBLIC_URL + dropSubItem.link}`}>
                        {dropSubItem.title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  ));
  return <>{navbarItems}</>;
};

export default NavItem;
