import { useEffect, useRef } from 'react';

export const useShareLinks = (url: string) => {
  const encoded = encodeURIComponent(url);
  const fbShare = useRef<string>();
  const twitterShare = useRef<string>();
  const pinterestShare = useRef<string>();

  useEffect(() => {
    fbShare.current = `https://www.facebook.com/sharer/sharer.php?u=${encoded}&amp;src=sdkpreparse`;
    twitterShare.current = `https://twitter.com/intent/tweet?text=${encoded}`;
    pinterestShare.current = `http://pinterest.com/pin/create/button/?url=${encoded}&media=&description=`;
  }, [encoded]);

  return [fbShare.current, twitterShare.current, pinterestShare.current];
};
