import React from 'react';
import Logo from './Logo';
import NavBar from './Navbar';
import HeaderContainer from '../containers/HeaderContainer';

type Props = {
  classes?: string;
  sideHeader?: boolean;
};

export const Header = ({ classes, sideHeader }: Props) => {
  return (
    <HeaderContainer classes={classes || ''}>
      <Logo sideHeader={sideHeader} />
      <NavBar />
      {/* <HeaderSocial /> */}
    </HeaderContainer>
  );
};

export default Header;
