import React, { useState, useEffect } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import { Card, ListGroup } from 'react-bootstrap';
import 'react-h5-audio-player/lib/styles.css';

import { MusicTrack } from '../features/models/musicSlice';

type Props = {
  sourceList: MusicTrack[];
};

const MusicPlayer = (props: Props) => {
  const { sourceList } = props;
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentTitle, setCurrentTitle] = useState<string | undefined>(
    undefined
  );
  const [currentSource, setCurrentSource] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (sourceList.length > 0 && currentTitle === undefined) {
      setCurrentSource(sourceList[0].filePath);
      setCurrentTitle(sourceList[0].name);
    }
  }, [sourceList, currentTitle]);

  const updateCurrentTrack = (index: number, track: any) => {
    setCurrentIndex(index);
    setCurrentTitle(track.name);
    setCurrentSource(track.filePath);
  };

  const onNext = () => {
    const newIndex = (currentIndex + 1) % sourceList.length;
    updateCurrentTrack(newIndex, sourceList[newIndex]);
  };

  const onPrevious = () => {
    const newIndex =
      currentIndex - 1 > -1 ? currentIndex - 1 : sourceList.length - 1;
    updateCurrentTrack(newIndex, sourceList[newIndex]);
  };

  return (
    <Card
      style={{
        margin: 'auto',
        width: '50%',
      }}>
      <Card.Header>
        <AudioPlayer
          autoPlay
          showSkipControls
          src={currentSource}
          header={currentTitle}
          onClickNext={onNext}
          onClickPrevious={onPrevious}
        />
      </Card.Header>
      <Card.Body>
        <ListGroup
          variant={'flush'}
          style={{
            width: '50%',
          }}>
          {sourceList.map((track, index) => {
            return track.show ? (
              <ListGroup.Item
                key={`${track.name}`}
                onClick={() => updateCurrentTrack(index, track)}
                active={currentSource === track.filePath}
                style={{
                  cursor: 'pointer',
                  borderBottom: '1px solid gray',
                  paddingTop: '5px',
                  paddingLeft: '10px',
                }}>
                {track.name}
              </ListGroup.Item>
            ) : null;
          })}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default MusicPlayer;
