import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk, RootState } from '../../app/store';
import { api } from '../api';

export type DrawingImage = {
  id: number;
  name: string;
  filePath: string;
  filename: string;
};

export type Drawing = {
  id: number;
  name: string;
  description: string;
  show: boolean;
  isFeatured: boolean;
  madeOn: boolean;
  thumb: string;
  thumbFilename: string;
  imageDetails: DrawingImage;
  order: number;
};

type DrawingState = {
  isLoading: boolean;
  drawingObjects: Drawing[];
  error: boolean | string;
};

const initialState: DrawingState = {
  isLoading: false,
  drawingObjects: [],
  error: false,
};

export const drawingSlice = createSlice({
  name: 'drawings',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    drawingsSuccess: (state, action: PayloadAction<any[]>) => {
      state.drawingObjects = action.payload;
      state.isLoading = false;
    },
    hasError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { drawingsSuccess, hasError, startLoading } = drawingSlice.actions;

export const fetchDrawings = (): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api
      .get('/drawings')
      .then((response) => dispatch(drawingsSuccess(response.data)));
  } catch (e) {
    dispatch(hasError(e.message));
  }
};

export const getDrawings = (state: RootState) => state.drawings.drawingObjects;

export const getDrawingsLoading = (state: RootState) =>
  state.drawings.isLoading;

export default drawingSlice.reducer;
