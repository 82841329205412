import React from 'react';
import { Box } from '@material-ui/core';
import { SculptureImage } from '../features/models/sculpturesSlice';
import Scrollbars from 'react-custom-scrollbars';
import { useStyles } from '../features/hooks/useStyles';

type Props = {
  images: SculptureImage[];
  onImageClicked: (index: number) => void;
};

export const SculptureDetailsImages = (props: Props) => {
  const { images, onImageClicked } = props;
  const classes = useStyles();

  return (
    <Box
      height={'100%'}
      style={{ paddingRight: 25 }}
      onMouseEnter={() => (document.body.style.cursor = 'pointer')}
      onMouseLeave={() => (document.body.style.cursor = 'auto')}>
      <Scrollbars style={{ width: '100%', height: 'calc(100vh - 200px)' }}>
        <div className={classes.sculptureDetailsImageGalleryContainer}>
          {images.map((image, index) => (
            <div
              className={classes.sculptureDetailsImage}
              key={`${image.filename}`}>
              <img
                src={image.filePath}
                alt={image.filePath}
                onClick={() => onImageClicked(index)}
              />
            </div>
          ))}
        </div>
      </Scrollbars>
    </Box>
  );
};
