import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from '../app/store';
import { fetchDrawings } from '../features/models/drawingsSlice';
import PortfolioDetails from '../components/PortfolioDetails';
import { SculptureDetailsImages } from '../components/SculptureDetailsImages';
import { useStyles } from '../features/hooks/useStyles';
import Lightbox from 'react-image-lightbox';

type ParamType = {
  id: string;
};

export const SculptureDetails = () => {
  const { id } = useParams<ParamType>();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState<number>(0);
  const classes = useStyles();
  let sculpture = useSelector((state: RootState) =>
    state.sculptures.sculptureObjects.find(
      (sculpture) => sculpture.id.toString() === id
    )
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDrawings());
  }, [dispatch]);

  if (sculpture) {
    const { images } = sculpture;

    const getImageUrl = (index: number) => {
      return images[index].filePath;
    };

    return (
      <div className={classes.sculptureDetailsContainer}>
        <div className={classes.sculptureDetailsImagesContainer}>
          <SculptureDetailsImages
            images={images}
            onImageClicked={() => setLightboxOpen(true)}
          />
        </div>
        <div className={classes.sculptureDetailsContentContainer}>
          <PortfolioDetails content={sculpture} />
        </div>
        {lightboxOpen && (
          <Lightbox
            mainSrc={getImageUrl(imageIndex)}
            nextSrc={getImageUrl((imageIndex + 1) % images.length)}
            prevSrc={getImageUrl(
              (imageIndex + images.length - 1) % images.length
            )}
            onCloseRequest={() => setLightboxOpen(false)}
            onMovePrevRequest={() =>
              setImageIndex((imageIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setImageIndex((imageIndex + 1) % images.length)
            }
          />
        )}
      </div>
    );
  } else {
    return null;
  }
};
