import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import FrontPageContainer from '../containers/FrontPageContainer';
import DrawingsContainer from '../containers/DrawingsContainer';
import DrawingDetails from './DrawingDetails';
import MusicPageContainer from '../containers/MusicPageContainer';
import { SculpturesContainer } from '../containers/SculpturesContainer';
import { ErrorPage } from './ErrorPage';
import { SculptureDetails } from './SculptureDetails';
import { useStyles } from '../features/hooks/useStyles';
import { setIsMobile } from '../features/appSlice';
import {
  fetchMusic,
  fetchMusicCategories,
} from '../features/models/musicSlice';
import {
  fetchSculptureCategories,
  fetchSculptures,
} from '../features/models/sculpturesSlice';
import { fetchDrawings } from '../features/models/drawingsSlice';
import { Contact } from './Contact';
import PageContainer from '../containers/PageContainer';
import { ComingSoonPage } from './ComingSoonPage';

const Home = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(setIsMobile(window.innerWidth < 992));
    dispatch(fetchMusic());
    dispatch(fetchMusicCategories());
    dispatch(fetchSculptures());
    dispatch(fetchSculptureCategories());
    dispatch(fetchDrawings());
  }, [dispatch]);

  return (
    <div className={classes.mainWrapper}>
      <BrowserRouter>
        <Header />
        <PageContainer>
          <Switch>
            <Route path="/drawing-details/:id" component={DrawingDetails} />
            <Route path="/drawings" component={DrawingsContainer} />
            <Route path="/music/:category" component={MusicPageContainer} />
            <Route path="/music" component={MusicPageContainer} />
            <Route path="/sculpture-details/:id" component={SculptureDetails} />
            <Route path="/sculpture-details" component={ErrorPage} />
            <Route
              path="/sculpture/:category"
              component={SculpturesContainer}
            />
            <Route path="/sculpture" component={SculpturesContainer} />
            <Route path="/error" component={ErrorPage} />
            <Route path={'/contact'} component={Contact} />
            <Route path="/" component={FrontPageContainer} />
          </Switch>
        </PageContainer>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default Home;
