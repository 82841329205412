import React from 'react';
import { Box } from '@material-ui/core';

type Props = {
  path: string;
  onImageClicked: () => void;
};

export const DrawingDetailsImage = (props: Props) => {
  const { path, onImageClicked } = props;

  return (
    <Box
      height={'100%'}
      style={{ paddingRight: 25 }}
      onMouseEnter={() => (document.body.style.cursor = 'pointer')}
      onMouseLeave={() => (document.body.style.cursor = 'auto')}
      onClick={onImageClicked}>
      <img src={path} alt={path} height={'100%'} />
    </Box>
  );
};
