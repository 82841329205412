import { makeStyles } from '@material-ui/styles';

export const useStyles = () => {
  const myStyles = makeStyles({
    portfolioImageContainer: {
      display: 'inline-flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      margin: 0,
      padding: 0,
      height: '100%',
    },
    portfolioImageElement: {
      flexBasis: '20%',
    },
    portfolioThumb: {
      height: '100%',
      width: '100%',
    },
    errorPage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sculptureDetailsContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    sculptureDetailsImagesContainer: {
      flex: 3,
      marginLeft: 40,
    },
    sculptureDetailsImageGalleryContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      margin: 0,
      padding: 0,
    },
    sculptureDetailsImage: {
      flexBasis: '25%',
      padding: 5,
    },
    sculptureDetailsContentContainer: {
      flex: 1,
      marginRight: 40,
    },
    mainWrapper: {
      paddingTop: '110px',
      paddingBottom: '90px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },
    pageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      padding: '10px',
    },
    drawingDetailsContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  });
  return myStyles();
};
