import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk, RootState } from '../../app/store';
import { api } from '../api';

export type MusicTrack = {
  id: number;
  name: string;
  description: string;
  category: number;
  filePath: string;
  filename: string;
  order: number;
  show: boolean;
  isFeatured: boolean;
};

export type MusicCategory = {
  id: number;
  name: string;
  tracks: MusicTrack[];
  thumb: string;
  thumbFilename: string;
  isFeatured: boolean;
  order: number;
  show: boolean;
};

type MusicState = {
  isLoading: boolean;
  musicObjects: MusicTrack[];
  error: boolean | string;
  musicCategoryObjects: MusicCategory[];
};

const initialState: MusicState = {
  isLoading: false,
  musicObjects: [],
  error: false,
  musicCategoryObjects: [],
};

export const musicSlice = createSlice({
  name: 'music',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    musicSuccess: (state, action: PayloadAction<any[]>) => {
      state.musicObjects = action.payload;
      state.isLoading = false;
    },
    hasError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    categorySuccess: (state, action: PayloadAction<any>) => {
      state.musicCategoryObjects = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  musicSuccess,
  categorySuccess,
  hasError,
  startLoading,
} = musicSlice.actions;

export const fetchMusic = (): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api
      .get('/music')
      .then((response) => dispatch(musicSuccess(response.data)));
  } catch (e) {
    dispatch(hasError(e.message));
  }
};

export const fetchMusicCategories = (): AppThunk => async (dispatch) => {
  dispatch(startLoading);
  try {
    await api
      .get('/music_categories')
      .then((response) => dispatch(categorySuccess(response.data)));
  } catch (e) {
    dispatch(hasError(e.message));
  }
};

export const getMusic = (state: RootState) => state.music.musicObjects;

export const getMusicLoading = (state: RootState) => state.music.isLoading;

export default musicSlice.reducer;
