import React from 'react';
import { Container, Row } from 'react-bootstrap';

type Props = {
  children: any;
  classes?: string;
};

const HeaderContainer = (props: Props) => {
  const { children, classes } = props;
  return (
    <div className={`header-section section ${classes}`}>
      <Container fluid>
        <Row
          className="align-items-center"
          style={{
            alignItems: 'center!important',
            display: 'flex',
            flexWrap: 'wrap',
            marginLeft: '-15px',
            marginRight: '-15px',
          }}>
          {children}
        </Row>
      </Container>
    </div>
  );
};

export default HeaderContainer;
