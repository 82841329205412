import React, { ReactNode } from 'react';
import 'perfect-scrollbar-react/dist/style.min.css';
import { useStyles } from '../features/hooks/useStyles';
import { Scrollbars } from 'react-custom-scrollbars';

type Props = {
  children: ReactNode;
};

const PageContainer = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <Scrollbars style={{ width: '100%', height: 'calc(100vh - 200px)' }}>
      <div className={classes.pageContainer}>{children}</div>
    </Scrollbars>
  );
};

export default PageContainer;
