import React from 'react';

export const Footer = () => {
  return (
    <div
      className="footer-section position-fixed section pt-30 pb-30"
      style={{
        left: '0',
        bottom: '0',
        width: '100%',
        paddingBottom: '30px',
      }}>
      <div
        className="container-fluid"
        style={{
          padding: '0 170px',
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}>
        <div
          className="row align-items-center"
          style={{
            alignContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            marginRight: '-15px',
            marginLeft: '-15px',
          }}>
          <div
            className="footer-copyright col-lg-4 col-12 order-lg-1 order-3"
            style={{
              fontSize: '14px',
              textTransform: 'uppercase',
              margin: 'auto',
              lineHeight: '30px',
              left: '15px',
              paddingLeft: '15px',
              paddingRight: '15px',
            }}>
            <p>
              &copy; {new Date().getFullYear()} SEAN FINLEY. ALL RIGHT RESERVED
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
