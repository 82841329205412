import React from 'react';
import './css/master.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'react-image-lightbox/style.css';

import Home from './pages/Home';

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
